/*Section - Custom - Features*/

div > .col-md-8 > h2 {
	margin: 20px !important;
	font-size: 2em !important;
}

div > .col-md-4 > h2 {
	margin: 20px !important;
	font-size: 2em !important;

}