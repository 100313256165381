div > .recent-posts > h2 {
    font-size: 2.2em !important;
    font-weight: 300 !important;
    line-height: 42px !important;
    margin: 0 0 32px 0 !important;
}

div > .col-md-6 > h2 {
    font-size: 2.2em !important;
    font-weight: 300 !important;
    line-height: 42px !important;
    margin: 0 0 32px 0 !important;
}
