/*Section | Element | social*/

/* Social Icons */
.social-icons {
	margin: 0;
	padding: 0;
	width: auto;
}

.social-icons li {
	display: inline-block;
	margin: -1px 1px 5px 0;
	padding: 0;
	border-radius: 100%;
	overflow: visible;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3) !important;
}

.social-icons li a {
	-webkit-transition: all 0.2s ease;
	-moz-transition: all 0.2s ease;
	transition: all 0.2s ease;
	background: #FFF;
	/*border-radius: 100%;*/
	display: block;
	height: 30px;
	line-height: 30px;
	width: 30px;
	text-align: center;
	color: #333 !important;
	text-decoration: none;
}

.social-icons li:hover a {
	background: #171717;
	color: #FFF !important;
}

.social-icons li:hover.social-icons-twitter a {
	background: #1aa9e1;
}

.social-icons li:hover.social-icons-facebook a {
	background: #3b5a9a;
}

.social-icons li:hover.social-icons-linkedin a {
	background: #0073b2;
}

.social-icons li:hover.social-icons-rss a {
	background: #ff8201;
}

.social-icons li:hover.social-icons-googleplus a {
	background: #dd4b39;
}

.social-icons li:hover.social-icons-pinterest a {
	background: #cc2127;
}

.social-icons li:hover.social-icons-youtube a {
	background: #c3191e;
}

.social-icons li:hover.social-icons-instagram a {
	background: #7c4a3a;
}

.social-icons li:hover.social-icons-skype a {
	background: #00b0f6;
}

.social-icons li:hover.social-icons-email a {
	background: #dd4b39;
}

.social-icons li:hover.social-icons-vk a {
	background: #6383a8;
}

.social-icons li:hover.social-icons-xing a {
	background: #1a7576;
}

.social-icons li:hover.social-icons-tumblr a {
	background: #304e6c;
}

.social-icons li:hover.social-icons-reddit a {
	background: #ff4107;
}

.social-icons.social-icons-transparent li {
	box-shadow: none;
}

.social-icons.social-icons-transparent li a {
	background: transparent;
}

.social-icons.social-icons-icon-light li a i {
	color: #FFF;
}


/* Thumb Info Social Icons */
.thumb-info-social-icons {
	border-top: 1px dotted #DDD;
	margin: 0;
	padding: 15px 10px;
	display: block;
}

.thumb-info-social-icons a {
	background: #CCC;
	border-radius: 25px;
	display: inline-block;
	height: 30px;
	line-height: 30px;
	text-align: center;
	width: 30px;
}

.thumb-info-social-icons a:hover {
	text-decoration: none;
}

.thumb-info-social-icons a span {
	display: none;
}

.thumb-info-social-icons a i {
	color: #FFF;
	font-size: 0.9em;
	font-weight: normal;
}