/*MAIN - Section - Latest - Blog*/

.thumb-info-side-image-custom {
    border: 0 none;
    color: #777;
    margin: 0 0 31px;
}

.box-shadow-custom {
    box-shadow: 10px 10px 74px -15px rgba(0, 0, 0, 0.33);
}
.thumb-info {
    display: block;
    position: relative;
    text-decoration: none;
    max-width: 100%;
    background-color: #FFF;
    border: 1px solid #DDD;
    border-radius: 4px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.thumb-info-side-image-custom.thumb-info-side-image-custom-highlight .thumb-info-side-image-wrapper {
    margin: 0 0 10px 0;
    padding: 0;
}
.thumb-info-side-image-custom .thumb-info-side-image-wrapper {
    padding: 0;
    margin-right: 30px;
}
.thumb-info-side-image .thumb-info-side-image-wrapper {
    padding: 4px;
    float: left;
    margin-right: 20px;
}
.thumb-info-side-image-custom {
    border: 0 none;
    color: #777;
    margin: 0 0 31px;
}
.thumb-info.thumb-info-no-zoom img {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}
.thumb-info-side-image-custom.thumb-info-side-image-custom-highlight .thumb-info-caption {
    padding: 0 10px;
    display: block;
    clear: both;
}
.thumb-info-side-image-custom .thumb-info-caption {
    text-align: left;
}
.thumb-info-caption {
    padding: 10px 0;
}