/* Buttons */
.btn {
	-webkit-transition: all 0.1s;
	-moz-transition: all 0.1s;
	transition: all 0.1s;
}

.btn-default {
	color: #666;
}

.btn-icon i {
	margin-right: 10px;
}

.btn-icon-right i {
	margin-right: 0;
	margin-left: 10px;
}

.btn-primary {
	border-color: #CCC;
	background-color: #CCC;
	/*border-color: #CCC #CCC #b3b3b3;*/
	color: #FFF;
}

.btn-primary:hover {
	border-color: #d9d9d9 #d9d9d9 #bfbfbf;
	background-color: #d9d9d9;
	color: #FFF;
}

.btn-primary:active, .btn-primary:focus {
	border-color: #bfbfbf;
	background-color: #bfbfbf;
	color: #FFF;
}

.btn-primary.dropdown-toggle {
	border-left-color: #bfbfbf;
}

.btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:active, .btn-primary[disabled]:focus {
	border-color: white;
	background-color: white;
	color: #CCC;
}

.btn-success {
	border-color: #47a447;
	background-color: #47a447;
	border-color: #47a447 #47a447 #388038;
	color: #FFF;
}

.btn-success:hover {
	border-color: #51b451 #51b451 #3f923f;
	background-color: #51b451;
	color: #FFF;
}

.btn-success:active, .btn-success:focus {
	border-color: #3f923f;
	background-color: #3f923f;
	color: #FFF;
}

.btn-success.dropdown-toggle {
	border-left-color: #3f923f;
}

.btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:active, .btn-success[disabled]:focus {
	border-color: #86cb86;
	background-color: #86cb86;
	color: #CCC;
}

.btn-warning {
	border-color: #ed9c28;
	background-color: #ed9c28;
	border-color: #ed9c28 #ed9c28 #d18211;
	color: #FFF;
}

.btn-warning:hover {
	border-color: #efa740 #efa740 #e89113;
	background-color: #efa740;
	color: #FFF;
}

.btn-warning:active, .btn-warning:focus {
	border-color: #e89113;
	background-color: #e89113;
	color: #FFF;
}

.btn-warning.dropdown-toggle {
	border-left-color: #e89113;
}

.btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:active, .btn-warning[disabled]:focus {
	border-color: #f5c786;
	background-color: #f5c786;
	color: #CCC;
}

.btn-danger {
	border-color: #d2322d;
	background-color: #d2322d;
	border-color: #d2322d #d2322d #a82824;
	color: #FFF;
}

.btn-danger:hover {
	border-color: #d74742 #d74742 #bd2d29;
	background-color: #d74742;
	color: #FFF;
}

.btn-danger:active, .btn-danger:focus {
	border-color: #bd2d29;
	background-color: #bd2d29;
	color: #FFF;
}

.btn-danger.dropdown-toggle {
	border-left-color: #bd2d29;
}

.btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:active, .btn-danger[disabled]:focus {
	border-color: #e48481;
	background-color: #e48481;
	color: #CCC;
}

.btn-info {
	border-color: #5bc0de;
	background-color: #5bc0de;
	border-color: #5bc0de #5bc0de #31b0d5;
	color: #FFF;
}

.btn-info:hover {
	border-color: #70c8e2 #70c8e2 #46b8da;
	background-color: #70c8e2;
	color: #FFF;
}

.btn-info:active, .btn-info:focus {
	border-color: #46b8da;
	background-color: #46b8da;
	color: #FFF;
}

.btn-info.dropdown-toggle {
	border-left-color: #46b8da;
}

.btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:active, .btn-info[disabled]:focus {
	border-color: #b0e1ef;
	background-color: #b0e1ef;
	color: #CCC;
}

.btn-dark {
	border-color: #1d2127;
	background-color: #1d2127;
	border-color: #1d2127 #1d2127 #07080a;
	color: #FFF;
}

.btn-dark:hover {
	border-color: #282d36 #282d36 #121518;
	background-color: #282d36;
	color: #FFF;
}

.btn-dark:active, .btn-dark:focus {
	border-color: #121518;
	background-color: #121518;
	color: #FFF;
}

.btn-dark.dropdown-toggle {
	border-left-color: #121518;
}

.btn-dark[disabled], .btn-dark[disabled]:hover, .btn-dark[disabled]:active, .btn-dark[disabled]:focus {
	border-color: #495362;
	background-color: #495362;
	color: #CCC;
}

/* Border Buttons */
.btn-borders {
	border-width: 3px;
}

/*Section - Custom*/
.btn-borders.btn-primary {
	background: transparent;
	/*border-color: #80b500;*/
	color: #CCC;
	text-shadow: none;
}

.btn-borders.btn-primary:hover, .btn-borders.btn-primary:focus {
	background-color: #d9d9d9;
	/*border-color: #119BD7 !important;*/
	color: #FFF;
}

.btn-borders.btn-success {
	background: transparent;
	border-color: #47a447;
	color: #47a447;
	text-shadow: none;
}

.btn-borders.btn-success:hover, .btn-borders.btn-success:focus {
	background-color: #51b451;
	border-color: #47a447 !important;
	color: #FFF;
}

.btn-borders.btn-warning {
	background: transparent;
	border-color: #ed9c28;
	color: #ed9c28;
	text-shadow: none;
}

.btn-borders.btn-warning:hover, .btn-borders.btn-warning:focus {
	background-color: #efa740;
	border-color: #ed9c28 !important;
	color: #FFF;
}

.btn-borders.btn-danger {
	background: transparent;
	border-color: #d2322d;
	color: #d2322d;
	text-shadow: none;
}

.btn-borders.btn-danger:hover, .btn-borders.btn-danger:focus {
	background-color: #d74742;
	border-color: #d2322d !important;
	color: #FFF;
}

.btn-borders.btn-info {
	background: transparent;
	border-color: #5bc0de;
	color: #5bc0de;
	text-shadow: none;
}

.btn-borders.btn-info:hover, .btn-borders.btn-info:focus {
	background-color: #70c8e2;
	border-color: #5bc0de !important;
	color: #FFF;
}

.btn-borders.btn-dark {
	background: transparent;
	border-color: #1d2127;
	color: #1d2127;
	text-shadow: none;
}

.btn-borders.btn-dark:hover, .btn-borders.btn-dark:focus {
	background-color: #282d36;
	border-color: #1d2127 !important;
	color: #FFF;
}

/* Border Buttons - Sizes */
.btn-borders {
	padding: 4px 12px;
}

.btn-borders.btn-lg, .btn-group-lg > .btn-borders.btn {
	padding: 8px 16px;
}

.btn-borders.btn-sm, .btn-group-sm > .btn-borders.btn {
	border-width: 2px;
	padding: 4px 10px;
}

.btn-borders.btn-xs, .btn-group-xs > .btn-borders.btn {
	padding: 1px 5px;
	border-width: 1px;
}

/* 3D Buttons */
.btn-3d {
	border-bottom-width: 3px;
	padding: 5px 12px;
	border-radius: 6px;
}

.btn-3d.btn-lg, .btn-group-lg > .btn-3d.btn {
	padding: 9px 16px;
}

.btn-3d.btn-sm, .btn-group-sm > .btn-3d.btn {
	border-width: 2px;
	padding: 4px 10px;
}

.btn-3d.btn-xs, .btn-group-xs > .btn-3d.btn {
	padding: 1px 5px;
	border-width: 1px;
}

/* Sizes */
.btn-xlg {
	border-radius: 6px;
	font-size: 16px;
	padding: 12px 33px;
}



/*Section | Frame | BUTTON STYLE */
.btn-link {
	color: #80b500;
}

.btn-link:hover {
	color: #92cf00;
}

.btn-link:active {
	color: #6e9c00;
}

html .btn-primary {
	background-color: #80b500;
	/*border-color: #80b500 #80b500 #5c8200;*/
	color: #FFF;
}

html .btn-primary:hover,
html .btn-primary.hover {
	background-color: #9bdb00;
	/*border-color: #a4e800 #a4e800 #80b500;*/
	color: #FFF;
}

html .btn-primary:focus,
html .btn-primary.focus {
	box-shadow: 0 0 0 3px rgba(128, 181, 0, 0.5);
}

html .btn-primary.disabled,
html .btn-primary:disabled {
	background-color: #80b500;
	border-color: #80b500 #80b500 #5c8200;
}

html .btn-primary:active,
html .btn-primary.active,
.show > html .btn-primary.dropdown-toggle {
	background-color: #658f00 !important;
	background-image: none !important;
	border-color: #5c8200 #5c8200 #384f00 !important;
	box-shadow: 0 0 0 0.2rem rgba(128, 181, 0, 0.5) !important;
}

html .btn-primary-scale-2 {
	background-color: #5c8200;
	border-color: #5c8200 #5c8200 #384f00;
	color: #FFF;
}

html .btn-primary-scale-2:hover,
html .btn-primary-scale-2.hover {
	background-color: #77a800;
	border-color: #80b500 #80b500 #5c8200;
	color: #FFF;
}

html .btn-primary-scale-2:focus,
html .btn-primary-scale-2.focus {
	box-shadow: 0 0 0 3px rgba(92, 130, 0, 0.5);
}

html .btn-primary-scale-2.disabled,
html .btn-primary-scale-2:disabled {
	background-color: #5c8200;
	border-color: #5c8200 #5c8200 #384f00;
}

html .btn-primary-scale-2:active,
html .btn-primary-scale-2.active,
.show > html .btn-primary-scale-2.dropdown-toggle {
	background-color: #415c00 !important;
	background-image: none !important;
	border-color: #384f00 #384f00 #141c00 !important;
	box-shadow: 0 0 0 0.2rem rgba(92, 130, 0, 0.5) !important;
}

html .btn-secondary {
	background-color: #119bd7;
	border-color: #119bd7 #119bd7 #0d79a8;
	color: #FFF;
}

html .btn-secondary:hover,
html .btn-secondary.hover {
	background-color: #21afed;
	border-color: #2db4ee #2db4ee #119bd7;
	color: #FFF;
}

html .btn-secondary:focus,
html .btn-secondary.focus {
	box-shadow: 0 0 0 3px rgba(17, 155, 215, 0.5);
}

html .btn-secondary.disabled,
html .btn-secondary:disabled {
	background-color: #119bd7;
	border-color: #119bd7 #119bd7 #0d79a8;
}

html .btn-secondary:active,
html .btn-secondary.active,
.show > html .btn-secondary.dropdown-toggle {
	background-color: #0e81b4 !important;
	background-image: none !important;
	border-color: #0d79a8 #0d79a8 #0a5778 !important;
	box-shadow: 0 0 0 0.2rem rgba(17, 155, 215, 0.5) !important;
}

html .btn-secondary-scale-2 {
	background-color: #0d79a8;
	border-color: #0d79a8 #0d79a8 #0a5778;
	color: #FFF;
}

html .btn-secondary-scale-2:hover,
html .btn-secondary-scale-2.hover {
	background-color: #1092cb;
	border-color: #119bd7 #119bd7 #0d79a8;
	color: #FFF;
}

html .btn-secondary-scale-2:focus,
html .btn-secondary-scale-2.focus {
	box-shadow: 0 0 0 3px rgba(13, 121, 168, 0.5);
}

html .btn-secondary-scale-2.disabled,
html .btn-secondary-scale-2:disabled {
	background-color: #0d79a8;
	border-color: #0d79a8 #0d79a8 #0a5778;
}

html .btn-secondary-scale-2:active,
html .btn-secondary-scale-2.active,
.show > html .btn-secondary-scale-2.dropdown-toggle {
	background-color: #0a5f84 !important;
	background-image: none !important;
	border-color: #0a5778 #0a5778 #063549 !important;
	box-shadow: 0 0 0 0.2rem rgba(13, 121, 168, 0.5) !important;
}

html .btn-tertiary {
	background-color: #e5edf1;
	border-color: #e5edf1 #e5edf1 #c4d6df;
	color: #777;
}

html .btn-tertiary:hover,
html .btn-tertiary.hover {
	background-color: #fefefe;
	border-color: #ffffff #ffffff #e5edf1;
	color: #777;
}

html .btn-tertiary:focus,
html .btn-tertiary.focus {
	box-shadow: 0 0 0 3px rgba(229, 237, 241, 0.5);
}

html .btn-tertiary.disabled,
html .btn-tertiary:disabled {
	background-color: #e5edf1;
	border-color: #e5edf1 #e5edf1 #c4d6df;
}

html .btn-tertiary:active,
html .btn-tertiary.active,
.show > html .btn-tertiary.dropdown-toggle {
	background-color: #ccdce4 !important;
	background-image: none !important;
	border-color: #c4d6df #c4d6df #a3bfcd !important;
	box-shadow: 0 0 0 0.2rem rgba(229, 237, 241, 0.5) !important;
}

html .btn-tertiary-scale-2 {
	background-color: #c4d6df;
	border-color: #c4d6df #c4d6df #a3bfcd;
	color: #777;
}

html .btn-tertiary-scale-2:hover,
html .btn-tertiary-scale-2.hover {
	background-color: #dde7ed;
	border-color: #e5edf1 #e5edf1 #c4d6df;
	color: #777;
}

html .btn-tertiary-scale-2:focus,
html .btn-tertiary-scale-2.focus {
	box-shadow: 0 0 0 3px rgba(196, 214, 223, 0.5);
}

html .btn-tertiary-scale-2.disabled,
html .btn-tertiary-scale-2:disabled {
	background-color: #c4d6df;
	border-color: #c4d6df #c4d6df #a3bfcd;
}

html .btn-tertiary-scale-2:active,
html .btn-tertiary-scale-2.active,
.show > html .btn-tertiary-scale-2.dropdown-toggle {
	background-color: #abc5d2 !important;
	background-image: none !important;
	border-color: #a3bfcd #a3bfcd #82a8bb !important;
	box-shadow: 0 0 0 0.2rem rgba(196, 214, 223, 0.5) !important;
}

html .btn-quaternary {
	background-color: #172536;
	border-color: #172536 #172536 #080c12;
	color: #FFF;
}

html .btn-quaternary:hover,
html .btn-quaternary.hover {
	background-color: #223751;
	border-color: #263e5a #263e5a #172536;
	color: #FFF;
}

html .btn-quaternary:focus,
html .btn-quaternary.focus {
	box-shadow: 0 0 0 3px rgba(23, 37, 54, 0.5);
}

html .btn-quaternary.disabled,
html .btn-quaternary:disabled {
	background-color: #172536;
	border-color: #172536 #172536 #080c12;
}

html .btn-quaternary:active,
html .btn-quaternary.active,
.show > html .btn-quaternary.dropdown-toggle {
	background-color: #0c131b !important;
	background-image: none !important;
	border-color: #080c12 #080c12 #000000 !important;
	box-shadow: 0 0 0 0.2rem rgba(23, 37, 54, 0.5) !important;
}

html .btn-quaternary-scale-2 {
	background-color: #080c12;
	border-color: #080c12 #080c12 #000000;
	color: #FFF;
}

html .btn-quaternary-scale-2:hover,
html .btn-quaternary-scale-2.hover {
	background-color: #131f2d;
	border-color: #172536 #172536 #080c12;
	color: #FFF;
}

html .btn-quaternary-scale-2:focus,
html .btn-quaternary-scale-2.focus {
	box-shadow: 0 0 0 3px rgba(8, 12, 18, 0.5);
}

html .btn-quaternary-scale-2.disabled,
html .btn-quaternary-scale-2:disabled {
	background-color: #080c12;
	border-color: #080c12 #080c12 #000000;
}

html .btn-quaternary-scale-2:active,
html .btn-quaternary-scale-2.active,
.show > html .btn-quaternary-scale-2.dropdown-toggle {
	background-color: #000000 !important;
	background-image: none !important;
	border-color: #000000 #000000 #000000 !important;
	box-shadow: 0 0 0 0.2rem rgba(8, 12, 18, 0.5) !important;
}

html .btn-dark {
	background-color: #2e353e;
	border-color: #2e353e #2e353e #181c21;
	color: #FFF;
}

html .btn-dark:hover,
html .btn-dark.hover {
	background-color: #3e4854;
	border-color: #444e5b #444e5b #2e353e;
	color: #FFF;
}

html .btn-dark:focus,
html .btn-dark.focus {
	box-shadow: 0 0 0 3px rgba(46, 53, 62, 0.5);
}

html .btn-dark.disabled,
html .btn-dark:disabled {
	background-color: #2e353e;
	border-color: #2e353e #2e353e #181c21;
}

html .btn-dark:active,
html .btn-dark.active,
.show > html .btn-dark.dropdown-toggle {
	background-color: #1e2228 !important;
	background-image: none !important;
	border-color: #181c21 #181c21 #030303 !important;
	box-shadow: 0 0 0 0.2rem rgba(46, 53, 62, 0.5) !important;
}

html .btn-dark-scale-2 {
	background-color: #181c21;
	border-color: #181c21 #181c21 #030303;
	color: #FFF;
}

html .btn-dark-scale-2:hover,
html .btn-dark-scale-2.hover {
	background-color: #292f37;
	border-color: #2e353e #2e353e #181c21;
	color: #FFF;
}

html .btn-dark-scale-2:focus,
html .btn-dark-scale-2.focus {
	box-shadow: 0 0 0 3px rgba(24, 28, 33, 0.5);
}

html .btn-dark-scale-2.disabled,
html .btn-dark-scale-2:disabled {
	background-color: #181c21;
	border-color: #181c21 #181c21 #030303;
}

html .btn-dark-scale-2:active,
html .btn-dark-scale-2.active,
.show > html .btn-dark-scale-2.dropdown-toggle {
	background-color: #08090b !important;
	background-image: none !important;
	border-color: #030303 #030303 #000000 !important;
	box-shadow: 0 0 0 0.2rem rgba(24, 28, 33, 0.5) !important;
}

html .btn-light {
	background-color: #FFF;
	border-color: #FFF #FFF #e6e6e6;
	color: #777;
}

html .btn-light:hover,
html .btn-light.hover {
	background-color: #ffffff;
	border-color: #ffffff #ffffff #FFF;
	color: #777;
}

html .btn-light:focus,
html .btn-light.focus {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

html .btn-light.disabled,
html .btn-light:disabled {
	background-color: #FFF;
	border-color: #FFF #FFF #e6e6e6;
}

html .btn-light:active,
html .btn-light.active,
.show > html .btn-light.dropdown-toggle {
	background-color: #ececec !important;
	background-image: none !important;
	border-color: #e6e6e6 #e6e6e6 #cccccc !important;
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important;
}

html .btn-light-scale-2 {
	background-color: #e6e6e6;
	border-color: #e6e6e6 #e6e6e6 #cccccc;
	color: #777;
}

html .btn-light-scale-2:hover,
html .btn-light-scale-2.hover {
	background-color: #f9f9f9;
	border-color: #ffffff #ffffff #e6e6e6;
	color: #777;
}

html .btn-light-scale-2:focus,
html .btn-light-scale-2.focus {
	box-shadow: 0 0 0 3px rgba(230, 230, 230, 0.5);
}

html .btn-light-scale-2.disabled,
html .btn-light-scale-2:disabled {
	background-color: #e6e6e6;
	border-color: #e6e6e6 #e6e6e6 #cccccc;
}

html .btn-light-scale-2:active,
html .btn-light-scale-2.active,
.show > html .btn-light-scale-2.dropdown-toggle {
	background-color: #d2d2d2 !important;
	background-image: none !important;
	border-color: #cccccc #cccccc #b3b3b3 !important;
	box-shadow: 0 0 0 0.2rem rgba(230, 230, 230, 0.5) !important;
}

html .btn-outline.btn-primary {
	color: #80b500;
	background-color: transparent;
	background-image: none;
	border-color: #80b500;
}

html .btn-outline.btn-primary:hover,
html .btn-outline.btn-primary.hover {
	color: #FFF;
	background-color: #80b500;
	border-color: #80b500;
}

html .btn-outline.btn-primary:focus,
html .btn-outline.btn-primary.focus {
	box-shadow: 0 0 0 3px rgba(128, 181, 0, 0.5);
}

html .btn-outline.btn-primary.disabled,
html .btn-outline.btn-primary:disabled {
	color: #80b500;
	background-color: transparent;
}

html .btn-outline.btn-primary:active,
html .btn-outline.btn-primary.active,
.show > html .btn-outline.btn-primary.dropdown-toggle {
	color: #FFF !important;
	background-color: #80b500 !important;
	border-color: #80b500 !important;
	box-shadow: 0 0 0 0.2rem rgba(128, 181, 0, 0.5) !important;
}

html .btn-outline.btn-secondary {
	color: #119bd7;
	background-color: transparent;
	background-image: none;
	border-color: #119bd7;
}

html .btn-outline.btn-secondary:hover,
html .btn-outline.btn-secondary.hover {
	color: #FFF;
	background-color: #119bd7;
	border-color: #119bd7;
}

html .btn-outline.btn-secondary:focus,
html .btn-outline.btn-secondary.focus {
	box-shadow: 0 0 0 3px rgba(17, 155, 215, 0.5);
}

html .btn-outline.btn-secondary.disabled,
html .btn-outline.btn-secondary:disabled {
	color: #119bd7;
	background-color: transparent;
}

html .btn-outline.btn-secondary:active,
html .btn-outline.btn-secondary.active,
.show > html .btn-outline.btn-secondary.dropdown-toggle {
	color: #FFF !important;
	background-color: #119bd7 !important;
	border-color: #119bd7 !important;
	box-shadow: 0 0 0 0.2rem rgba(17, 155, 215, 0.5) !important;
}

html .btn-outline.btn-tertiary {
	color: #e5edf1;
	background-color: transparent;
	background-image: none;
	border-color: #e5edf1;
}

html .btn-outline.btn-tertiary:hover,
html .btn-outline.btn-tertiary.hover {
	color: #777;
	background-color: #e5edf1;
	border-color: #e5edf1;
}

html .btn-outline.btn-tertiary:focus,
html .btn-outline.btn-tertiary.focus {
	box-shadow: 0 0 0 3px rgba(229, 237, 241, 0.5);
}

html .btn-outline.btn-tertiary.disabled,
html .btn-outline.btn-tertiary:disabled {
	color: #e5edf1;
	background-color: transparent;
}

html .btn-outline.btn-tertiary:active,
html .btn-outline.btn-tertiary.active,
.show > html .btn-outline.btn-tertiary.dropdown-toggle {
	color: #777 !important;
	background-color: #e5edf1 !important;
	border-color: #e5edf1 !important;
	box-shadow: 0 0 0 0.2rem rgba(229, 237, 241, 0.5) !important;
}

html .btn-outline.btn-quaternary {
	color: #172536;
	background-color: transparent;
	background-image: none;
	border-color: #172536;
}

html .btn-outline.btn-quaternary:hover,
html .btn-outline.btn-quaternary.hover {
	color: #FFF;
	background-color: #172536;
	border-color: #172536;
}

html .btn-outline.btn-quaternary:focus,
html .btn-outline.btn-quaternary.focus {
	box-shadow: 0 0 0 3px rgba(23, 37, 54, 0.5);
}

html .btn-outline.btn-quaternary.disabled,
html .btn-outline.btn-quaternary:disabled {
	color: #172536;
	background-color: transparent;
}

html .btn-outline.btn-quaternary:active,
html .btn-outline.btn-quaternary.active,
.show > html .btn-outline.btn-quaternary.dropdown-toggle {
	color: #FFF !important;
	background-color: #172536 !important;
	border-color: #172536 !important;
	box-shadow: 0 0 0 0.2rem rgba(23, 37, 54, 0.5) !important;
}

html .btn-outline.btn-dark {
	color: #2e353e;
	background-color: transparent;
	background-image: none;
	border-color: #2e353e;
}

html .btn-outline.btn-dark:hover,
html .btn-outline.btn-dark.hover {
	color: #FFF;
	background-color: #2e353e;
	border-color: #2e353e;
}

html .btn-outline.btn-dark:focus,
html .btn-outline.btn-dark.focus {
	box-shadow: 0 0 0 3px rgba(46, 53, 62, 0.5);
}

html .btn-outline.btn-dark.disabled,
html .btn-outline.btn-dark:disabled {
	color: #2e353e;
	background-color: transparent;
}

html .btn-outline.btn-dark:active,
html .btn-outline.btn-dark.active,
.show > html .btn-outline.btn-dark.dropdown-toggle {
	color: #FFF !important;
	background-color: #2e353e !important;
	border-color: #2e353e !important;
	box-shadow: 0 0 0 0.2rem rgba(46, 53, 62, 0.5) !important;
}

html .btn-outline.btn-light {
	color: #FFF;
	background-color: transparent;
	background-image: none;
	border-color: #FFF;
}

html .btn-outline.btn-light:hover,
html .btn-outline.btn-light.hover {
	color: #777;
	background-color: #FFF;
	border-color: #FFF;
}

html .btn-outline.btn-light:focus,
html .btn-outline.btn-light.focus {
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5);
}

html .btn-outline.btn-light.disabled,
html .btn-outline.btn-light:disabled {
	color: #FFF;
	background-color: transparent;
}

html .btn-outline.btn-light:active,
html .btn-outline.btn-light.active,
.show > html .btn-outline.btn-light.dropdown-toggle {
	color: #777 !important;
	background-color: #FFF !important;
	border-color: #FFF !important;
	box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5) !important;
}

/*Section | Simpler */
.custom-btn-style-1 {
	padding: 13px 25px !important;
	font-size: 12px;
	border-radius: 2px !important;
}

.custom-btn-style-2 {
	padding: 13px 30px;
	font-size: 15px;
	border-width: 2px;
}

.custom-btn-style-3 {
	border-width: 1px;
	padding: 10px 20px;
}

.custom-btn-style-4 {
	background: none;
	color: #FFF;
	font-weight: 900;
	border: 2px solid #FFF;
	padding: 10px 20px;
}
.custom-btn-style-4:hover {
	background: #FFF;
	color: #80b500;
}