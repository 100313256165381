// Origin
@import "origin/simpler";
@import "origin/structure";
@import "origin/frame";
/*@import "origin/element";*/


// Base
@import "base/reset";
@import "base/variables";
@import "base/typography";

// Components
@import "components/buttons";
@import "components/callouts";
@import "components/footers";
@import "components/buttons";
/*@import "components/navs";*/
@import "components/pannels";
@import "components/players";
@import "components/features";
@import "components/intro";
@import "components/main-blog";
@import "components/typo";

// Elements
@import "elements/lists";
@import "elements/navs";
@import "elements/tables";
@import "elements/forms";

// Layouts
@import "layouts/about";
@import "layouts/contact";
@import "layouts/guide";
@import "layouts/home";
@import "layouts/services";